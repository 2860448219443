<template>
	<div class="course-detail">
		<nav-bar class="nav-bar" id="titleArea" ref="nav"></nav-bar>
		<div class="detail-content mb-39" id="middleArea">
			<div class="course-base-info align-center mb-14">
				<img :src='course.logo' class="info-img" />
				<div class="info-right">
					<div class="title">{{ course.courseName }}</div>
					<div class="mt-16 align-center">
						<img src="../../assets/img/common/book.svg" class="info-icon" />
						<div class="hour">共{{ course.classHour }}课时</div>
					</div>
					<div class="mt-10 grade">适用年级：{{ course.gradeNames }}</div>
					<!-- <div class="mt-11 grade introduce" :title='course.note'>课程介绍：{{ course.note }}</div> -->
				</div>
			</div>
			<div class="d-flex mb-39">
				<div class="content-left mr-11">
					<div class="ts">课程大纲</div>
					<!-- <div class="ts" style="text-align: left;cursor: pointer;color: #3E7EFF; margin-top: 16px;" @click="requestLoginHome">返回
                    </div> -->
					<div class="jie" v-for="(itemP, indexP) in courses">
						<div class="align-center zhang">
							<img src="../../assets/img/common/book.svg" class="left-icon" />
							<div>{{ itemP.typeName }}</div>
						</div>
						<div class="left-list" v-for="(item, index) in itemP.chaps">
							<div class="flex-acfw jiang">
								<div class="title text-over">{{ item.title }}
								</div>
								<div class="align-center bi">
									<div class="progress">
										<div class="progress-b" v-bind:style="{ width: item.hourRate + '%' }"></div>
									</div>
									<div class="c6">{{ item.hourRate }}%</div>
								</div>
								<img :src="(item.teachType == 'ppt' || item.teachType == 'pptx') ?
									require('../../assets/img/common/powerpoint.png') :
									(item.teachType == 'doc' || item.teachType == 'docx') ?
										require('../../assets/img/common/word.png') :
										item.teachType == 'pdf' ?
											require('../../assets/img/common/pdf.png') :
											require('../../assets/img/common/powerpoint.png')" title="课件" class="jiang-img cursor-p"
									@click="ChapterFile(item.id, item.teachPPT, 1, itemP.typeId)" />

								<img :src="(item.handoutType == 'ppt' || item.handoutType == 'pptx') ?
									require('../../assets/img/common/powerpoint.png') :
									(item.handoutType == 'doc' || item.handoutType == 'docx') ?
										require('../../assets/img/common/word.png') :
										item.handoutType == 'pdf' ?
											require('../../assets/img/common/pdf.png') :
											require('../../assets/img/common/pdf.png')" title="教案" class="jiang-img cursor-p"
									@click="ChapterFile(item.id, item.handoutPPT, 2, '')" />

								<img :src="(item.stuType == 'ppt' || item.stuType == 'pptx') ?
									require('../../assets/img/common/powerpoint.png') :
									(item.stuType == 'doc' || item.stuType == 'docx') ?
										require('../../assets/img/common/word.png') :
										item.stuType == 'pdf' ?
											require('../../assets/img/common/pdf.png') :
											require('../../assets/img/common/pdf.png')" title="学生讲义" class="jiang-img cursor-p"
									@click="ChapterFile(item.id, item.stuManual, 3, '')" />


							</div>
						</div>
					</div>
				</div>
				<div class="content-right">
					<!-- <div class="about">相关下载：</div> -->
					<img :src='course.logo' class="mt-11" />
					<div class="type mb-14">{{ course.courseName }}</div>

					<div class="mt-11" style="font-size:12px;line-height: 2;" :title='course.note'>
						&nbsp;&nbsp;&nbsp;&nbsp;课程介绍：{{ course.note }}</div>

					<!-- <div class="align-center">
                        <div class="ppt cursor-p" @click="CourseFile(course.lessonFile)">课程ppt</div>
                        <div class="ppt cursor-p">教案</div>
                    </div>
                    <div class="mt-9 jy cursor-p">学生讲义</div> -->

				</div>
			</div>
		</div>
		<bottom-bar class="course-bottom" />
	</div>
</template>
<script>
import {
	CourseInfo,
	CourseRole
} from '@/api/coursedetail'
import {
	getInfo
} from '@/api/login'

import {
	Message,
	MessageBox
} from 'element-ui' //导入element-ui组件库
import NavBar from "../../components/content/navBar/NavBar"
import BottomBar from '../../components/content/bottomBar/BottomBar'
import {
	getToken,
	setToken,
	removeToken
} from '@/utils/cookies.js'

export default {
	name: 'CourseDetail',
	components: {
		NavBar,
		BottomBar
	},
	data() {
		return {
			islogin: false,
			//用户信息
			users: {},
			//课程主键
			courseId: '',
			//课程类型主键
			typeId: '',
			//是否拥有课程权限
			courseRole: {},
			//课程信息列表
			courses: [],
			//课程信息
			course: {},
			//课程节点树
			courseTree: [],
			//权限参数
			listQuery: {
				gradeIds: '',
				courseTypeId: '',
				loginid: ''
			}
		}
	},
	methods: {
		//登录
		requestLogin() {
			this.$router.push('/login');
		},
		//退出登录
		loginOut() {
			removeToken()
			this.$router.push('/login');
		},
		//跳转登录后首页
		requestLoginHome() {
			if (this.users.id) {
				this.$router.push('/loginHome');
			} else {
				this.$router.push('/home');
			}
		},
		//用户信息
		async userInfo() {
			var token = getToken();
			if (token) {		
				await getInfo(token).then(response => {
					if (response.status == 200) {
						this.users = response.data;
					}
					setTimeout(() => {
						this.listLoading = false
					}, 1.5 * 1000)
				})
			}
		},
		//课程信息
		GetCourse() {
			this.listLoading = true
			this.courseId = this.$route.query.id;
			this.typeId = this.$route.query.typeId;
			CourseInfo({
				'courseId': this.courseId,
				'loginId': this.users.id,
				'typeId': this.typeId
			}).then(response => {
				if (response.status == 200) {
					for (var i = 0; i < response.data.length; i++) {
						for (var j = 0; j < response.data[i].chaps.length; j++) {
							//课程占比
							//if (response.data[i].chaps[j].totalPage) {
							let rate = 0;
							let totalTime = 600;
							let timeLength = response.data[i].chaps[j].timeLength;
							if (timeLength == 0) {
								rate = 0
							}
							if (timeLength >= totalTime) {
								rate = 100;
							} else {
								rate = parseFloat(timeLength / totalTime * 100).toFixed(1);
							}
							/*按页码计算*/
							// let total = response.data[i].chaps[j].totalPage;
							// let currentPage = response.data[i].chaps[j].currentPage;
							// let rate = 0;
							// if (total == 0 || currentPage == 0) {
							// 	rate = 0;
							// } else if (currentPage >= total) {
							// 	rate = 100;
							// } else {
							// 	rate = parseFloat(currentPage / total * 100).toFixed(2);
							// }
							this.$set(response.data[i].chaps[j], 'hourRate', rate);

							// } else {
							// 	this.$set(response.data[i].chaps[j], 'hourRate', 0);
							// }
							//课件PPT(TeachPPT)
							let teachType = 'ppt';
							if (response.data[i].chaps[j].teachPPT) {
								var teachSplet = response.data[i].chaps[j].teachPPT.split('.');
								if (teachSplet.length > 1) {
									teachType = teachSplet[1];
								}
							}
							this.$set(response.data[i].chaps[j], 'teachType', teachType);
							//教案PPT(HandoutPPT)
							let handoutType = 'pdf';
							if (response.data[i].chaps[j].handoutPPT) {
								var handoutSplet = response.data[i].chaps[j].handoutPPT.split('.');
								if (handoutSplet.length > 1) {
									handoutType = handoutSplet[1];
								}
							}
							this.$set(response.data[i].chaps[j], 'handoutType', handoutType);
							//学生讲义(StuManual)
							let stuType = 'pdf';
							if (response.data[i].chaps[j].stuManual) {
								var stuSplet = response.data[i].chaps[j].stuManual.split('.');
								if (stuSplet.length > 1) {
									stuType = stuSplet[1];
								}
							}
							this.$set(response.data[i].chaps[j], 'stuType', stuType);
						}
					}

					this.courses = response.data;
					if (this.courses.length > 0) {
						this.course = this.courses[0];
					}

				} else {
					MessageBox.alert(response.message, "错误", {
						confirmButtonText: '确定',
						type: 'error'
					})
				}
				setTimeout(() => {
					this.listLoading = false
				}, 1.5 * 1000)
			})
		},
		//节点PPT(type=1:课件2:教案3:学生讲义)
		ChapterFile(id, url, type, typeid) {
			var token = getToken();
			if (token) {
				if (url) {
					this.listQuery.gradeIds = this.course.gradeIds;
					this.listQuery.courseTypeId = this.course.typeId;
					this.listQuery.loginid = this.users.id;
					CourseRole(this.listQuery).then(response => {
						if (response) {
							if (response.status == 200) {
								if (response.data.isCourseRole == true) {
									let pathInfo = this.$router.resolve({
										path: '/coursePlayOffice',
										query: {
											id: id,
											type: type,
											userid: this.users.id,
											schoolId: this.users.schoolId,
											gradeId: this.course.gradeIds,
											courseId: this.$route.query.id,
											courseTypeId: typeid//this.course.typeId
										}
									})
									localStorage.setItem("type", type);
									localStorage.setItem("chapterid", id);
									localStorage.setItem("userid", this.users.id);
									window.open(pathInfo.href, '_blank');

								} else {
									MessageBox.alert("无权限进入", "提示", {
										confirmButtonText: '确定',
										type: 'info'
									})
								}
							} else {
								MessageBox.alert("无权限进入", "提示", {
									confirmButtonText: '确定',
									type: 'info'
								})
							}
						}
						setTimeout(() => {
							this.listLoading = false
						}, 1.5 * 1000)
					})
				} else {
					MessageBox.alert("章节无文档！", "提示", {
						confirmButtonText: '确定',
						type: 'info'
					})
				}
			} else {
				MessageBox.alert("必须登录才可以进入学习！", "提示", {
					confirmButtonText: '确定',
					type: 'info'
				})
			}
		}
	},
	created() {
		Promise.all([this.userInfo()]).then(() => {
			this.GetCourse();
		})
	},
	mounted() {
		this.$refs.nav.mdd = 1;
		localStorage.setItem("mdd", 1);

		const titleHeight = document.getElementById('titleArea').clientHeight;
        const middleArea = document.getElementById('middleArea');
        const mheight = document.documentElement.clientHeight;
        middleArea.style.minHeight = (Number(mheight) - (Number(titleHeight) + 50)) + 'px';

	}
}
</script>
<style scoped lang="less">
.course-detail {
	min-width: 1000px;

	.nav-bar {
		.bar-icon {
			border-radius: 50%;
			vertical-align: -2px;
			margin-right: 7px;
			width: 13px;
			height: 13px;
		}
	}

	.detail-content {
		padding-left: 9.1rem;
		padding-right: 9.1rem;

		.course-base-info {
			background-color: #fff;
			padding: 16px 19px 16px;

			.info-img {
				margin-right: 14px;
				width: 171px;
				height: 114px;
			}

			.info-right {
				width: calc(100% - 185px);

				.title {
					color: #303133;
					font-size: 12px;
					font-weight: 600;
				}

				.info-icon {
					width: 9px;
					height: 8px;
					margin-right: 3px;
				}

				.hour {
					color: #303133;
					font-size: 12px;
				}

				.grade {
					color: #6A6E76;
					font-size: 12px;
				}

				.introduce {
					line-height: 16px;
					text-overflow: ellipsis;
					overflow: hidden;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
		}

		.content-left {
			background-color: #fff;
			flex: 1;
			border-radius: 5px;
			padding: 17px;

			.ts {
				color: #303133;
				font-size: 12px;
				font-weight: 600;
			}

			.left-icon {
				width: 9px;
				height: 8px;
				margin-right: 3px;
			}

			.jie {
				border-bottom: 1px dashed #E7E7E7;
				padding-bottom: 3px;

				&:last-child {
					border-bottom: none;
				}
			}

			.zhang {
				color: #303133;
				font-size: 12px;
				margin-bottom: 18px;
				font-weight: 600;
				margin-top: 17px;
			}

			.left-list {
				color: #303133;
				font-size: 12px;
				padding-left: 32px;

				.title {
					flex: 1;
				}

				.bi {
					width: 254px;
				}

				.progress {
					background-color: #EDEFF1;
					width: 200.8px;
					height: 5px;
					border-radius: 3px;
					margin-right: 10px;
					margin-left: 0.4px;
				}

				.progress-b {
					height: 100%;
					background-color: #3E7EFF;
					border-radius: 3px;
				}

				.go {
					color: #3E7EFF;
					//margin-left: 33.6px;
					//width: 96px;
					margin-left: 13.6px;
				}

				.jiang {
					margin-bottom: 14px;

					.jiang-img {
						max-width: 25px;
						min-width: 25px;
						margin-left: 13px;
					}
				}
			}
		}

		.content-right {
			background-color: #fff;
			width: 25%;
			border-radius: 5px;
			padding: 11px 11px 18px;

			.about {
				color: #6A6E76;
				font-size: 12px;
				font-weight: 600;
			}

			img {
				width: 100%;
				border-radius: 5px;
			}

			.type {
				color: #303133;
				font-size: 12px;
				font-weight: 600;
				margin-top: 6px;
			}

			.ppt {
				margin-right: 9px;
				flex: 1;
				background-color: #3E7EFF;
				border-radius: 2px;
				color: #fff;
				font-size: 12px;
				text-align: center;
				height: 24px;
				line-height: 24px;

				&:last-child {
					margin-right: 0px;
				}
			}

			.jy {
				border: 1px solid #3E7EFF;
				background-color: rgba(62, 126, 255, 0.10);
				height: 24px;
				line-height: 24px;
				text-align: center;
				color: #3E7EFF;
				font-size: 12px;
				border-radius: 2px;
			}
		}
	}

	// .course-bottom{
	//     position:fixed;
	//     left:0px;
	//     right:0px;
	//     bottom:0px;
	// }
}
</style>