var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"course-detail"},[_c('nav-bar',{ref:"nav",staticClass:"nav-bar",attrs:{"id":"titleArea"}}),_c('div',{staticClass:"detail-content mb-39",attrs:{"id":"middleArea"}},[_c('div',{staticClass:"course-base-info align-center mb-14"},[_c('img',{staticClass:"info-img",attrs:{"src":_vm.course.logo}}),_c('div',{staticClass:"info-right"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.course.courseName))]),_c('div',{staticClass:"mt-16 align-center"},[_c('img',{staticClass:"info-icon",attrs:{"src":require("../../assets/img/common/book.svg")}}),_c('div',{staticClass:"hour"},[_vm._v("共"+_vm._s(_vm.course.classHour)+"课时")])]),_c('div',{staticClass:"mt-10 grade"},[_vm._v("适用年级："+_vm._s(_vm.course.gradeNames))])])]),_c('div',{staticClass:"d-flex mb-39"},[_c('div',{staticClass:"content-left mr-11"},[_c('div',{staticClass:"ts"},[_vm._v("课程大纲")]),_vm._l((_vm.courses),function(itemP,indexP){return _c('div',{staticClass:"jie"},[_c('div',{staticClass:"align-center zhang"},[_c('img',{staticClass:"left-icon",attrs:{"src":require("../../assets/img/common/book.svg")}}),_c('div',[_vm._v(_vm._s(itemP.typeName))])]),_vm._l((itemP.chaps),function(item,index){return _c('div',{staticClass:"left-list"},[_c('div',{staticClass:"flex-acfw jiang"},[_c('div',{staticClass:"title text-over"},[_vm._v(_vm._s(item.title)+" ")]),_c('div',{staticClass:"align-center bi"},[_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-b",style:({ width: item.hourRate + '%' })})]),_c('div',{staticClass:"c6"},[_vm._v(_vm._s(item.hourRate)+"%")])]),_c('img',{staticClass:"jiang-img cursor-p",attrs:{"src":(item.teachType == 'ppt' || item.teachType == 'pptx') ?
									require('../../assets/img/common/powerpoint.png') :
									(item.teachType == 'doc' || item.teachType == 'docx') ?
										require('../../assets/img/common/word.png') :
										item.teachType == 'pdf' ?
											require('../../assets/img/common/pdf.png') :
											require('../../assets/img/common/powerpoint.png'),"title":"课件"},on:{"click":function($event){return _vm.ChapterFile(item.id, item.teachPPT, 1, itemP.typeId)}}}),_c('img',{staticClass:"jiang-img cursor-p",attrs:{"src":(item.handoutType == 'ppt' || item.handoutType == 'pptx') ?
									require('../../assets/img/common/powerpoint.png') :
									(item.handoutType == 'doc' || item.handoutType == 'docx') ?
										require('../../assets/img/common/word.png') :
										item.handoutType == 'pdf' ?
											require('../../assets/img/common/pdf.png') :
											require('../../assets/img/common/pdf.png'),"title":"教案"},on:{"click":function($event){return _vm.ChapterFile(item.id, item.handoutPPT, 2, '')}}}),_c('img',{staticClass:"jiang-img cursor-p",attrs:{"src":(item.stuType == 'ppt' || item.stuType == 'pptx') ?
									require('../../assets/img/common/powerpoint.png') :
									(item.stuType == 'doc' || item.stuType == 'docx') ?
										require('../../assets/img/common/word.png') :
										item.stuType == 'pdf' ?
											require('../../assets/img/common/pdf.png') :
											require('../../assets/img/common/pdf.png'),"title":"学生讲义"},on:{"click":function($event){return _vm.ChapterFile(item.id, item.stuManual, 3, '')}}})])])})],2)})],2),_c('div',{staticClass:"content-right"},[_c('img',{staticClass:"mt-11",attrs:{"src":_vm.course.logo}}),_c('div',{staticClass:"type mb-14"},[_vm._v(_vm._s(_vm.course.courseName))]),_c('div',{staticClass:"mt-11",staticStyle:{"font-size":"12px","line-height":"2"},attrs:{"title":_vm.course.note}},[_vm._v("     课程介绍："+_vm._s(_vm.course.note))])])])]),_c('bottom-bar',{staticClass:"course-bottom"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }